export const BUSINESS_INDEX = 'businessIndex'
export const BUSINESS_PHOTOS = 'businessPhotos'
export const BUSINESS_ABOUT = 'businessAbout'
export const BUSINESS_REVIEWS = 'businessReviews'
export const BUSINESS_DEALS = 'businessDeals'
export const BUSINESS_DEAL = 'businessDeal'
export const BUSINESS_DEAL_CLAIM = 'businessDealClaim'
export const BUSINESS_CART = 'businessCart'
export const BUSINESS_301 = 'business301'
export const BUSINESS_PRODUCT = 'businessProduct'

export const BUSINESS_CHAIN_INDEX = 'chainListingIndex'
export const BUSINESS_CHAIN_PHOTOS = 'chainListingPhotos'
export const BUSINESS_CHAIN_REVIEWS = 'chainListingReviews'
export const BUSINESS_CHAIN_DEALS = 'chainListingDeals'
export const BUSINESS_CHAIN_PRODUCT = 'chainListingProduct'

export const MENU_ROUTES = [ BUSINESS_INDEX, BUSINESS_CHAIN_INDEX ]

export default {
	BUSINESS_INDEX,
	BUSINESS_PHOTOS,
	BUSINESS_ABOUT,
	BUSINESS_REVIEWS,
	BUSINESS_DEALS,
	BUSINESS_DEAL,
	BUSINESS_DEAL_CLAIM,
	BUSINESS_CART,
	BUSINESS_301,
	BUSINESS_PRODUCT,
	BUSINESS_CHAIN_INDEX,
	BUSINESS_CHAIN_DEALS,
	BUSINESS_CHAIN_PHOTOS,
	BUSINESS_CHAIN_REVIEWS,
	BUSINESS_CHAIN_PRODUCT,
	MENU_ROUTES
}
