<template>
	<div class="relative max-w-6xl px-3 mx-auto text-left border-b border-gray-200 md:static">
		<Breadcrumbs
			v-if="breadcrumbs.length"
			:breadcrumbs="breadcrumbs"
			:class="[
				isCheckout ? 'py-2.5' : 'my-2',
				'text-sm'
			]"
		/>
		<div
			v-if="isCheckout"
			class="hidden py-2 text-2xl font-bold md:block"
		>
			Checkout
		</div>
		<BusinessDetails :business="business || {}" />
		<div
			v-if="!isCheckout"
			class="flex items-end h-10"
		>
			<BusinessNavigation
				v-if="listingId"
				:business-url="business.url || ''"
				:listing-id="listingId"
				:categories="categories"
				@toggle-categories="toggleCategories"
				@turn-on-transition="handleTurnOnTransition"
			/>
		</div>
		<div
			v-if="isCheckout"
			class="border-b border-gray-200"
		/>
	</div>
</template>

<script async>

import Breadcrumbs from '@/components/multiUse/Breadcrumbs.vue'
import { BUSINESS_TYPE_ROUTES } from '@/constants/business/business-routes.js'
import isCheckoutPage from '@/utils/cart/is-checkout-page.js'

import BusinessDetails from './BusinessDetails.vue'
import BusinessNavigation from './BusinessNavigation.vue'

export default {
	components: {
		Breadcrumbs,
		BusinessNavigation,
		BusinessDetails
	},
	props: {
		business: {
			type: Object,
			required: true
		},
		categories: {
			type: Array,
			default: null
		}
	},
	emits: [
		'toggle-categories',
		'turn-on-transition'
	],
	computed: {
		breadcrumbs() {
			const crumbs = [ {
				url: '/', content: 'Home', srOnly: true
			} ]
			if (this.business?.address?.state?.textUrl && this.business?.address?.state?.name) {
				crumbs.push({
					url: this.business.address.state.textUrl, content: this.business.address.state.name, srOnly: true
				})
			}

			if (this.business?.cityUrl) {
				crumbs.push({
					url: this.business.cityUrl, content: this.business?.address?.city, srOnly: true
				})
			}

			if (this.isCheckout) {
				crumbs.push({ url: this.business?.url, content: 'Continue Shopping' })
			} else {
				crumbs.push({ url: this.businessTypeUrl, content: this.business?.typeName })
				crumbs.push({
					url: this.business?.url, content: this.business?.name, srOnly: true
				})
			}
			return crumbs
		},
		listingId() {
			return parseInt(this.business?.id)
		},
		isCheckout() {
			return isCheckoutPage(this.$route)
		},
		mediaMatch() {
			return this.$store.state.mediaMatch
		},
		isMobile() {
			return this.mediaMatch === 'sm' || this.mediaMatch === 'xs'
		},
		cityUrl() {
			return this.business?.cityUrl || ''
		},
		businessTypeUrl() {
			let typeRoute = ''
			if (this.business?.businessType) {
				typeRoute = BUSINESS_TYPE_ROUTES[this.business.businessType]
			}
			return this.cityUrl + '/' + typeRoute
		}
	},
	methods: {
		toggleCategories(showCategories, sectionPath) {
			this.$emit('toggle-categories', showCategories, sectionPath)
		},
		handleTurnOnTransition() { // emits up to business shell to turn on router-view transition when not updating route
			this.$emit('turn-on-transition')
		}
	}
}
</script>
