<template>
	<DefaultLayout :hide-footer="hideFooter">
		<div>
			<BusinessHeader
				v-if="showBusinessHeader"
				ref="profileHeader"
				:business="businessHeaderData || {}"
				:categories="categories"
				@toggle-categories="toggleCategories"
				@turn-on-transition="handleTurnOnTransition"
			/>
			<div class="relative max-w-6xl mx-auto text-left">
				<BusinessCategoriesSlider
					v-if="categories?.length"
					:categories="categories"
					:show-categories="showCategories"
					:is-mobile="isMobile"
					:section-path="sectionPath"
					@close-slider="closeCategorySlider"
				/>
				<Transition :name="routerViewTransition">
					<router-view v-if="showRouterView" />
				</Transition>
				<BusinessMobileCart
					v-if="showMobileCart"
					:cart="cart"
					:name="name"
					:logo="logo"
					:url="url"
					:listing-id="listingId"
					:settings="settings"
					rounded
				/>
			</div>
		</div>
	</DefaultLayout>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { fetchBusiness } from '@/api/map/index.js'
import BusinessHeader from '@/components/business/header/BusinessHeader.vue'
import {
	BUSINESS_CHAIN_DEALS,
	BUSINESS_CHAIN_INDEX,
	BUSINESS_CHAIN_PHOTOS,
	BUSINESS_CHAIN_PRODUCT,
	BUSINESS_CHAIN_REVIEWS,
	BUSINESS_DEALS,
	BUSINESS_INDEX,
	BUSINESS_PHOTOS,
	BUSINESS_PRODUCT,
	BUSINESS_REVIEWS,
	MENU_ROUTES
} from '@/constants/business/business-route-names.js'
import { GetBusinessDetails } from '@/gql/business/queries/components.gql'
import { trackImpression } from '@/gql/mutations/impressions.gql'
import DefaultLayout from '@/layouts/default/Index.vue'
import { componentLevelGQLErrors, logError, pageLevelGQLErrors } from '@/utils/error-handling.js'

const RECREATIONAL = 'recreational'
const MEDICAL = 'medical'

export default {
	components: {
		DefaultLayout,
		BusinessHeader,
		BusinessCategoriesSlider: () => import('@/views/business/BusinessCategoriesSlider.vue'),
		BusinessMobileCart: () => import('@/components/business/BusinessMobileCart.vue')
	},
	beforeRouteUpdate(to, from, next) { // this is to invoke updates when a route stays within the same shell
		const isSameBusiness = to.params?.business === from.params?.business
		if (!isSameBusiness) this.resetState()

		this.setRouterViewTransition()

		next()
	},
	data() {
		return {
			isMounted: false,
			mobileCartEnabled: false,
			sectionPath: '',
			showCategories: false,
			routerTransitionsOn: false,
			enableTransitionBeforeRouteUpdate: false
		}
	},
	apollo: {
		businessHeaderData: {
			query: GetBusinessDetails,
			update(data) {
				if (data.meta) {
					const needsDataForNearbyBusinesses = this.pageName === BUSINESS_PRODUCT && data.meta?.geoSections
					if (needsDataForNearbyBusinesses) {
						this.$store.dispatch('setGeoSections', data.meta.geoSections)
					}
					this.$store.dispatch('setMeta', data.meta)
				}
				if (data.listing) {
					this.setCategoriesAndId(data.listing.categories, data.listing.id)
					return {
						...data.listing
					}
				}
			},
			variables() {
				return {
					seoUrl: this.$route.params.business || '',
					chainSeoUrl: this.$route.params.chain || '',
					pageName: this.pageName
				}
			},
			error(error) {
				pageLevelGQLErrors(error, this.$store)
			}
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		pageName() {
			if (this.$route.query?.category) return 'listingMenu'

			switch (this.$route.name) {
				case BUSINESS_INDEX:
				case BUSINESS_CHAIN_INDEX:
					return 'listing'
				case BUSINESS_PRODUCT:
				case BUSINESS_CHAIN_PRODUCT:
					return 'listingMenu'
				case BUSINESS_PHOTOS:
				case BUSINESS_CHAIN_PHOTOS:
					// #TODO: add listingPhotos to gql MetaTypeEnum
					return 'listing'
				case BUSINESS_REVIEWS:
				case BUSINESS_CHAIN_REVIEWS:
					return 'listingReviews'
				case BUSINESS_DEALS:
				case BUSINESS_CHAIN_DEALS:
					return 'listingDeals'
				default:
					return 'listing'
			}
		},
		trackingSubpageEnum() {
			if (this.$route.query?.category) return 'subpageMenu'
			switch (this.$route.name) {
				case BUSINESS_DEALS:
				case BUSINESS_CHAIN_DEALS:
					return 'subpageDeals'
				case BUSINESS_REVIEWS:
				case BUSINESS_CHAIN_REVIEWS:
					return 'subpageReviews'
				case BUSINESS_PHOTOS:
				case BUSINESS_CHAIN_PHOTOS:
					return 'subpageGallery'
				case BUSINESS_PRODUCT:
				case BUSINESS_CHAIN_PRODUCT:
					return 'subpageMenu'
				default:
					return 'subpageIndex'
			}
		},
		showBusinessHeader() {
			return !this.$route.meta.dontShowBusinessHeader
		},
		showRouterView() {
			return !this.showCategories || !this.isMobile
		},
		routerViewTransition() {
			if (!this.isMobile) return 'disabled'
			return this.routerTransitionsOn ? 'slide-left' : 'disabled'
		},
		isMenuPage() {
			return MENU_ROUTES.includes(this.$route.name)
		},
		showMobileCart() {
			return this.mobileCartEnabled && this.isMenuPage && this.isMobile
		},
		hideFooter() {
			return this.showCategories || this.showMobileCart
		},
		businessUrl() {
			return this.businessHeaderData?.url || ''
		},
		categories() {
			return this.businessHeaderData?.categories?.map(category => Object.assign(category, { type: 'router-link' }))
				.map(category => Object.assign(category, { to: { path: this.businessUrl, query: { category: category?.name?.toLowerCase() } }, class: 'px-6 py-2 hover:text-green-500' }))
				.map(({
					name: text,
					itemsCount,
					...rest
				}) => ({
					text: `${text} (${itemsCount})`,
					...rest,
					queryText: text
				})).sort((category1, category2) => {
					return category1?.position - category2?.position
				}) || null
		},
		businessAboutReviews() {
			return this.businessHeaderData?.reviews || []
		},
		url() {
			return this.businessHeaderData?.url || ''
		},
		numberOfCategories() {
			return this.businessHeaderData?.categories?.length || 0
		},
		name() {
			return this.businessHeaderData?.name || ''
		},
		cart() {
			return this.businessHeaderData?.cart || {}
		},
		logo() {
			return this.businessHeaderData?.logo?.fileName || ''
		},
		listingId() {
			return String(this.businessHeaderData?.id) || ''
		},
		settings() {
			return this.businessHeaderData?.settings || {}
		}
	},
	watch: {
		isMounted: {
			immediate: true,
			handler(newVal) {
				if (newVal) this.initializeRouterTransition()
			}
		},
		$route: {
			immediate: true,
			handler() {
				if (this.isMenuPage && !this.showCategories) this.enableMobileCart()
				setTimeout(() => {
					this.enableRouterTransition()
				}, 300)

				this.$nextTick(() => {
					if (!this.businessHeaderData?.id) return
					try {
						this.$apollo.mutate({
							mutation: trackImpression,
							variables: {
								listingId: Number(this.businessHeaderData.id),
								pageType: 'pageBusiness',
								listingSubpage: this.trackingSubpageEnum
							}
						})
					} catch (e) {
						componentLevelGQLErrors(e)
					}
				})
			}
		},
		async businessHeaderData(newData, oldData) {
			if (newData.settings?.hasRecreational) {
				this.setDefaultIndication(RECREATIONAL)
			} else if (newData.settings?.hasMedical) {
				this.setDefaultIndication(MEDICAL)
			} else {
				this.setDefaultIndication(RECREATIONAL)
			}
			if (newData.settings.hasMedical && newData.settings.hasRecreational) {
				this.setHasMultipleIndications(true)
			}
			if (newData?.id && newData?.id !== oldData?.id) {
				try {
					const restBusiness = await fetchBusiness(newData.id)
					this.setCommonData(restBusiness.results)
					this.setCartData({ data: restBusiness.results.cart, settings: restBusiness.settings })
					// #TODO this REST call will need to be in place to load the cart until gql has the cart query functioning
				} catch (e) {
					logError(e)
				}
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	beforeDestroy() {
		this.resetState()
	},
	methods: {
		...mapActions('business', [ 'resetState' ]),
		...mapMutations('business', [ 'setDefaultIndication', 'setCategoriesExpanded', 'setCommonData', 'setHasMultipleIndications', 'setCategoriesAndId' ]),
		...mapMutations('cart', [ 'setCartData' ]),
		setRouterViewTransition() {
			if (this.showRouterView && !this.enableTransitionBeforeRouteUpdate) this.disableRouterTransition()
			else this.enableRouterTransition()
		},
		handleTurnOnTransition() { // turn the transition on when not routing (e.g. clicking on tab, then clicking on a category, then clicking the same tab again)
			this.enableTransitionBeforeRouteUpdate = true
			setTimeout(() => { // enable cart button and turn router-view transition back off before the next route update
				this.enableMobileCart()
				this.enableTransitionBeforeRouteUpdate = false
			}, 300)
		},
		initializeRouterTransition() {
			this.enableRouterTransition()
			if (this.isMenuPage && !this.showCategories) this.enableMobileCart()
		},
		enableRouterTransition() {
			this.routerTransitionsOn = true
		},
		disableRouterTransition() {
			this.routerTransitionsOn = false
		},
		toggleCategories(showCategories, sectionPath) {
			if (showCategories) this.setCategoryLeaveTransition(showCategories)
			else this.setCategoryEnterTransition(showCategories)

			if (sectionPath) this.sectionPath = sectionPath
		},
		setCategoryEnterTransition(showCategories) {
			this.showCategories = showCategories
			setTimeout(() => { // wait until just after the category slider is opened before doing cart button enter transition
				if (this.isMenuPage) this.enableMobileCart()
			}, 300)
		},
		setCategoryLeaveTransition(showCategories) {
			this.$nextTick(() => {
				this.enableRouterTransition() // turn transition back on if category slider is open for smooth leave transition
			})
			this.disableMobileCart() // hide cart button before leaving category slider
			if (this.isMenuPage) { // if on menu page, leave transition after 75ms to allow smooth leave transition for cart button
				setTimeout(() => {
					this.showCategories = showCategories
				}, 75)
			} else { // if not on menu page, leave transition immediately
				this.showCategories = showCategories
			}
		},
		closeCategorySlider() {
			this.setCategoriesExpanded(false) // reset the category button expanded state in BusinessNavigation
			this.toggleCategories(false) // set showCategories to false
		},
		enableMobileCart() {
			this.mobileCartEnabled = true
		},
		disableMobileCart() {
			this.mobileCartEnabled = false
		}
	},
	serverPrefetch() { // this is only SSR
		if (this.$route.params.garbage) { // this is to 301 garbage routes
			let redirect = this.$route.fullPath.split('/')
			redirect.pop()
			redirect = redirect.join('/')
			this.$store.commit('setError', { status: 301, redirect: redirect })
		}
	}
}
</script>
