var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative capitalize"},[_c('ul',{ref:"list",staticClass:"flex justify-start space-x-6 overflow-auto list-none flex-0 hide-horizontal-scrollbar min-h-10",style:({ 'scroll-behavior': 'smooth' })},_vm._l((_vm.navList),function(section,index){return _c('li',{key:section.path + index,staticClass:"text-gray-500 h-[42px]"},[(_vm.isCategories(section))?_c('div',{staticClass:"block py-2 text-left",class:{
					'text-black border-b-2 border-green-500':
						_vm.tabIsActive(section.text, section.path)
				}},[(_vm.isMobile)?_c('button',{staticClass:"flex items-center",class:{'font-bold': _vm.tabIsActive(section.text, section.path)},on:{"click":_vm.toggleCategories}},[_vm._v("\n\t\t\t\t\t"+_vm._s(section.text)+"\n\t\t\t\t")]):_c('WwDropdown',{attrs:{"title":"Categories","menu-items":_vm.categories,"button-classes":[
						'flex items-center',
						{ 'font-bold text-black': _vm.tabIsActive(section.text, section.path) }
					],"button-id":"menu-categories","content-width":"min-w-34","show-top-arrow":"","center-top-arrow":"","parent-reference-level":2,"parent-reference-name":"list","x-offset-override":42,"open-on-hover":""}})],1):(section.text !== 'Categories' && !_vm.categoriesExpanded)?_c('router-link',{class:[
					'block py-2 text-left',
					{
						'text-black font-bold border-b-2 border-green-500':
							_vm.tabIsActive(section.text, section.path)
					}
				],attrs:{"to":section},on:{"click":function($event){_vm.categoriesExpanded ? _vm.toggleCategories : false}}},[_vm._v("\n\t\t\t\t"+_vm._s(section.text)+"\n\t\t\t")]):(section.text !== 'Categories' && _vm.categoriesExpanded)?_c('button',{class:[
					'block py-2 text-left',
					{
						'text-black font-bold border-b-2 border-green-500':
							_vm.tabIsActive(section.text, section.path)
					}
				],on:{"click":function($event){return _vm.handleRoute(section.path, section.query)}}},[_vm._v("\n\t\t\t\t"+_vm._s(section.text)+"\n\t\t\t")]):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }