<template>
	<div class="relative capitalize">
		<ul
			ref="list"
			class="flex justify-start space-x-6 overflow-auto list-none flex-0 hide-horizontal-scrollbar min-h-10"
			:style="{ 'scroll-behavior': 'smooth' }"
		>
			<li
				v-for="(section, index) in navList"
				:key="section.path + index"
				class="text-gray-500 h-[42px]"
			>
				<div
					v-if="isCategories(section)"
					class="block py-2 text-left"
					:class="{
						'text-black border-b-2 border-green-500':
							tabIsActive(section.text, section.path)
					}"
				>
					<button
						v-if="isMobile"
						class="flex items-center"
						:class="{'font-bold': tabIsActive(section.text, section.path)}"
						@click="toggleCategories"
					>
						{{ section.text }}
					</button>
					<WwDropdown
						v-else
						title="Categories"
						:menu-items="categories"
						:button-classes="[
							'flex items-center',
							{ 'font-bold text-black': tabIsActive(section.text, section.path) }
						]"
						button-id="menu-categories"
						content-width="min-w-34"
						show-top-arrow
						center-top-arrow
						:parent-reference-level="2"
						parent-reference-name="list"
						:x-offset-override="42"
						open-on-hover
					/>
				</div>
				<router-link
					v-else-if="section.text !== 'Categories' && !categoriesExpanded"
					:to="section"
					:class="[
						'block py-2 text-left',
						{
							'text-black font-bold border-b-2 border-green-500':
								tabIsActive(section.text, section.path)
						}
					]"
					@click="categoriesExpanded ? toggleCategories : false"
				>
					{{ section.text }}
				</router-link>
				<!-- use this button when category slider is open to enable transitions on the router-view -->
				<button
					v-else-if="section.text !== 'Categories' && categoriesExpanded"
					:class="[
						'block py-2 text-left',
						{
							'text-black font-bold border-b-2 border-green-500':
								tabIsActive(section.text, section.path)
						}
					]"
					@click="handleRoute(section.path, section.query)"
				>
					{{ section.text }}
				</button>
			</li>
		</ul>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import WwDropdown from '@/components/UI/WwDropdown.vue'
import { LEFT, RIGHT } from '@/constants/positions.js'

export default {
	components: {
		WwDropdown
	},
	props: {
		businessUrl: {
			required: true,
			type: String
		},
		listingId: {
			required: true,
			type: Number
		},
		categories: {
			type: Array,
			default: null
		}
	},
	emits: [
		'toggle-categories',
		'turn-on-transition'
	],
	data() {
		return {
			LEFT,
			RIGHT
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		...mapGetters('business', [ 'defaultIndication', 'hasMultipleIndications' ]),
		...mapGetters('business', [ 'categoriesExpanded' ]),
		navList() {
			const categoryQuery = this.$route.query?.category || this.firstCategory
			const array = [
				{ path: `${this.businessUrl}`, text: 'Menu', query: this.indicationObject },
				{ path: `${this.businessUrl}`, text: 'Categories', query: { category: categoryQuery } },
				{ path: `${this.businessUrl}/deals`, text: 'Deals' },
				{ path: `${this.businessUrl}/reviews`, text: 'Reviews' },
				{ path: `${this.businessUrl}/gallery`, text: 'Photos' }
			]
			if (!this.showCategories) {
				array.splice(1, 1)
				return array
			} else return array
		},
		activeTabIndex() {
			return this.navList.indexOf(this.navList.filter(tab => this.tabIsActive(tab.text, tab.path))[0])
		},
		firstCategory() {
			return this.categories?.[0]?.text || ''
		},
		showCategories() {
			return !!this.categories?.length
		},
		indicationObject() {
			return this.$route.query?.indication !== this.defaultIndication ? { indication: this.$route.query.indication } : null
		},
		multipleIndicationsWithDefaultSet() {
			return this.defaultIndication && this.hasMultipleIndications
		},
		route() {
			return this.$route
		}
	},
	watch: {
		route() {
			this.setHasCategories(this.showCategories)
			if (this.categoriesExpanded) {
				this.toggleCategories()
			}
		},
		activeTabIndex() {
			this.$nextTick(() => {
				this.scrollToActiveTab()
			})
		},
		showCategories: {
			handler() {
				this.setHasCategories(this.showCategories)
			},
			immediate: true
		}
	},
	methods: {
		...mapMutations('business', [ 'setCategoriesExpanded', 'setHasCategories' ]),
		handleRoute(route, query) {
			this.$emit('turn-on-transition')
			this.toggleCategories()
			if (query) {
				this.$router.push({ path: route, query: query })
			} else {
				this.$router.push(route)
			}
		},
		tabIsActive(sectionText, sectionPath) {
			const route = this.$route.fullPath
			if (sectionText === 'Categories') return !!this.$route.query?.category || this.categoriesExpanded
			if (sectionText === 'Menu') return sectionPath === this.$route.path && !this.categoriesExpanded && !this.$route.query?.category
			return route === sectionPath && !this.categoriesExpanded
		},
		toggleCategories() {
			this.setCategoriesExpanded(!this.categoriesExpanded) // set categoriesExpanded in the store so that we can toggle it from other files
			this.$emit('toggle-categories', this.categoriesExpanded, this.navList[0].path)
		},
		scrollToActiveTab() {
			const parent = this.$refs.list
			const parentRect = parent?.getBoundingClientRect()
			const child = this.$refs.list.children[this.activeTabIndex]
			const childRect = child?.getBoundingClientRect()
			const isViewable = (childRect?.left >= parentRect?.left) && (childRect?.right <= parentRect?.right)
			if (!isViewable) {
				const scrollLeft = childRect?.left - parentRect?.left
				const scrollRight = childRect?.right - parentRect?.right
				if (Math.abs(scrollLeft) < Math.abs(scrollRight)) {
					this.$refs.list.scrollLeft += scrollLeft
				} else {
					this.$refs.list.scrollLeft += scrollRight
				}
			}
		},
		isCategories(section) {
			return section.text === 'Categories' && this.showCategories
		}
	}
}
</script>
